<template>
  <row
    v-if="articleList"
    class="edition-top-block">
    <column
      xs="12"
      lg="9">
      <row>
        <column
          xs="12"
          lg="8">
          <div class="d-flex">
            <div
              v-if="isBranded($route.meta.pageContext.type)"
              class="branded-logo">
              <img :src="$cdn.resource(`gfx/${$pubCtx.name}/branding/${$route.meta.pageContext.type}.png`)">
            </div>
            <h2
              v-else
              class="ff-flama fs-xxl fw-light tt-uppercase mb-4 mt-1">
              {{ pageTitle }}
            </h2>
            <AlertButton
              v-if="subscribable"
              :items="[topic]"
              :title="__('alert.button.title.section', pageTitle)"
              class="ml-2"
              sub-type="category"
              mini/>
          </div>
          <teaser
            v-if="articleList[0]"
            :teaser-element="articleList[0]"
            :show-main-category="false"
            teaser-type="extraextraLarge"
            image-size="big"
            eager/>
        </column>
        <column
          md="12"
          lg="4">
          <row>
            <column
              lg="12"
              sm="6"
              xs="12">
              <teaser
                v-if="articleList[1]"
                :teaser-element="articleList[1]"
                image-size="small"
                eager/>
            </column>
            <column
              lg="12"
              sm="6"
              xs="12">
              <teaser
                v-if="articleList[2]"
                :teaser-element="articleList[2]"
                image-size="small"
                eager/>
            </column>
          </row>
        </column>

        <!-- MPU1 ad on smaller screens -->
        <column
          xs="12"
          class="d-block d-lg-none mb-3 text-center">
          <ad slot-id="mediumRectangleMobile"/>
        </column>

        <!-- 2 large stories -->
        <column
          v-for="article in articleList.slice(3, 5)"
          :key="article.title"
          xs="12"
          sm="4">
          <teaser
            :teaser-element="article"
            image-size="small"
            eager/>
        </column>

        <!-- Sidekick ad on smaller screens -->
        <column
          xs="12"
          class="d-block d-sm-none mb-3 text-center">
          <ad slot-id="skyScraperMobile"/>
        </column>

        <!-- 2 large stories -->
        <column
          v-for="article in articleList.slice(5, 6)"
          :key="article.title"
          xs="12"
          sm="4">
          <teaser
            :teaser-element="article"
            image-size="small"
            eager/>
        </column>

        <column
          xs="12"
          class="d-block text-center">
          <ad
            slot-id="sponsoredPremiumContent"
            class-name="mb-3"/>
        </column>

        <column
          v-for="article in articleList.slice(6, 9)"
          :key="article.title"
          xs="12"
          sm="4">
          <teaser
            :teaser-element="article"
            image-size="small"
            eager/>
        </column>
      </row>
    </column>

    <!-- Ads -->
    <column
      xs="3"
      class="d-none d-lg-block">
      <div class="mb-3 text-center">
        <ad slot-id="brandBanner"/>
      </div>
      <div class="mb-3 text-center">
        <ad slot-id="skyScraper"/>
      </div>
      <div class="mb-3 text-center">
        <ad slot-id="mediumRectangle"/>
      </div>
    </column>
  </row>
</template>
<script>
import { AlertButton } from 'global-components';
import { isBranded } from 'global-utils/vue';
export default {
  name: 'edition-top-block',
  components: { AlertButton },
  props: {
    articleList: {
      type: Array,
      required: true
    }
  },
  data() {
    const { type, pageTitle, subscribable } = this.$route.meta.pageContext;
    return {
      pageTitle,
      subscribable,
      topic: { name: type, label: pageTitle }
    };
  },
  methods: {
    isBranded,
  },
};
</script>
