<template>
  <div>
    <div class="wrapper">
      <EditionTopBlock
        v-if="articleChunks[0]"
        :article-list="articleChunks[0]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="panorama"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="mb-3 mt-3 text-center">
      <ad slot-id="parallax"/>
    </div>
    <div class="wrapper">
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="billboard"/>
        </column>
      </row>
    </div>
    <div class="wrapper">
      <EditionMpuBlock
        v-if="articleChunks[1]"
        :article-list="articleChunks[1]"
        teaser-type="large"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[2]"
        :article-list="articleChunks[2]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="panoramaSecond"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[3]"
        :article-list="articleChunks[3]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[4]"
        :article-list="articleChunks[4]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="billboardSecond"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[5]"
        :article-list="articleChunks[5]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[6]"
        :article-list="articleChunks[6]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[7]"
        :article-list="articleChunks[7]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[8]"
        :article-list="articleChunks[8]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[9]"
        :article-list="articleChunks[9]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="articleChunks[10]"
        :article-list="articleChunks[10]"
        :hide-image="true"/>
    </div>
  </div>
</template>
<script>
import {
  EditionTopBlock,
  EditionMpuBlock,
  EditionRegularBlock,
} from './page-segments/index';
import { layoutResolver } from 'global-configs/page-config';
import { Page, Placeholder } from 'global-components';
import { emit } from 'global-hackable';

export default {
  name: 'edition-page',
  dataResolver: 'editionPage',
  components: {
    EditionTopBlock,
    EditionMpuBlock,
    EditionRegularBlock,
    Placeholder,
  },
  extends: Page,
  data() {
    return {
      articleChunks: this.sliceArticleList(this.$store.state.edition.articles),
    };
  },
  async created() {
    await emit('created:EditionPage', this);
  },
  methods: {
    sliceArticleList(articles) {
      const pageConfig = layoutResolver(
        this.$store.state.publication.code,
        this.$route.meta.pageContext.type
      );
      let end = 0;
      return pageConfig.newsBlocks.map(({ numberOfArticles }) => {
        const start = end;
        end = start + numberOfArticles;
        return articles.slice(start, end);
      });
    }
  }
};
</script>
