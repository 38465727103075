<template>
  <row v-if="articleList">
    <column
      md="9"
      xs="12">
      <row>
        <column
          v-for="article in articleList"
          :key="article.title"
          sm="6"
          xs="12">
          <teaser
            :teaser-element="article"
            :teaser-type="teaserType"
            image-size="small"/>
        </column>
      </row>

      <TwoColumnSponsorContentAdRow/>

    </column>
    <column
      xs="12"
      md="3"
      class="most-read-list mb-3 edition-news-lists">
      <h4 class="ff-flama fs-lg fw-light tt-uppercase pb-2 pl-2 list-header mb-0">
        <a
          :class="{'fw-bold': activetab === 1}"
          @click="activetab = 1">
          {{ __('most.read') }} <span class="fw-bold pl-2 pr-2">|</span>
        </a>
        <a
          :class="{'fw-bold': activetab === 2}"
          @click="activetab = 2">
          {{ resolveTitle() }}
        </a>
      </h4>
      <MostReadList v-show="activetab === 1" />
      <LatestNewsList v-show="activetab === 2" />
    </column>
  </row>
</template>

<script>
import { MostReadList, LatestNewsList } from 'global-components';
import TwoColumnSponsorContentAdRow from './TwoColumnSponsorContentAdRow';

export default {
  name: 'edition-mpu-block',
  components: {
    MostReadList,
    LatestNewsList,
    TwoColumnSponsorContentAdRow
  },
  props: {
    articleList: {
      type: Array,
      required: true
    },
    teaserType: {
      type: String,
      default: 'medium'
    }
  },
  data() {
    return {
      activetab: 1
    };
  },
  methods: {
    resolveTitle() {
      return this.$i18n(`latest.news.${this.$store.state.page.type}`) ||
        this.$i18n(`latest.news`);
    }
  }
};
</script>
