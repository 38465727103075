<template>
  <row class="mb-3">
    <column
      sm="6"
      xs="12"
      class="text-center">
      <ad slot-id="sponsoredContentFrontpage"/>
    </column>
    <column
      sm="6"
      xs="12"
      class="text-center">
      <ad slot-id="sponsoredContentFrontpageSecond"/>
    </column>
  </row>
</template>

<script>
export default {
  name: 'two-column-sponsor-content-ad-row'
};
</script>
