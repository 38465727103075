<template>
  <row v-if="articleList">
    <column xs="12">
      <row>
        <column
          v-for="article in articleList"
          :key="article.title"
          xs="12"
          sm="6"
          md="4">
          <teaser
            :teaser-element="article"
            :hide-image="hideImage"
            image-size="small"/>
        </column>
      </row>
    </column>
  </row>
</template>

<script>
export default {
  name: 'edition-regular-block',
  props: {
    articleList: {
      type: Array,
      required: true
    },
    hideImage: {
      type: Boolean,
      default: false
    }
  }
};
</script>
